$mozaic-env: development;
@import '@mozaic-ds/styles/settings-tools/all-settings';
@import "@mozaic-ds/styles/components/_c.modal";

#arm-popin-dsa-modal {

  .mc-modal--overflow .mc-modal__dialog {
    height: initial;
  }

  .arm-popin-dsa-empty {
    display: none;
  }

  .mc-modal__dialog.is-open {
    height: initial;
    align-self: center;
  }

  .mt-body-m {
    height: 100%;
  }

  #arm-popin-dsa-modal-content {
    height: 100%;
    display: flex;
  }

  .mc-modal__body {
    display: flex;
    flex-direction: column;
    flex: min-content 0 0;
    padding-bottom: 3rem;
    padding-top: 1rem;

    .mc-modal__footer {
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }
  }

}

.mc-loader{
  height: 4rem;
  width: 4rem;
  align-self: center;
  margin: auto;
}